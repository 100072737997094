import React, { useEffect, useMemo, useRef, useState } from 'react';
import { differenceInDays, intervalToDuration, parseISO } from 'date-fns';
import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import styled from 'styled-components';

const pad = (str: string | number | undefined) => (str === undefined ? '00' : String(str).padStart(2, '0'));

type TimerProps = {
  targetTime: string;
  textColor?: React.CSSProperties['color'];
  onFinished?: () => void;
};

const Timer = ({ targetTime, onFinished, textColor = 'inherit' }: TimerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const target = useMemo(() => parseISO(targetTime), [targetTime]);

  const [now, setNow] = useState(Date.now());

  const { hours, minutes, seconds } = intervalToDuration({ start: now, end: target });
  const days = differenceInDays(target, now);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const hasFinished = useRef(false);
  useEffect(() => {
    if (!hasFinished.current) {
      if (target.getTime() <= now) {
        onFinished?.();
        hasFinished.current = true;
        setNow(target.getTime());
      } else {
        const timeoutId = setTimeout(() => setNow(Date.now()), 1000);
        return () => clearTimeout(timeoutId);
      }
    }

    // No-op because of ts and eslint rules
    return () => {};
  }, [onFinished, now, target]);

  return (
    <TimerWrapper $textColor={textColor}>
      <TimerEntry title="Dager" number={isLoaded ? days : '--'} />
      <TimerEntry title="Timer" number={isLoaded ? hours : '--'} />
      <TimerEntry title="Minutter" number={isLoaded ? minutes : '--'} />
      <TimerEntry title="Sekunder" number={isLoaded ? seconds : '--'} />
    </TimerWrapper>
  );
};

const TimerEntry = ({ title, number }: { title: string; number?: number | string }) => (
  <TimerEntryWrapper>
    <TimerNumber variant={TextVariant.Header1} tag="h3">
      {pad(number)}
    </TimerNumber>
    <Text variant={TextVariant.BodyTextHeader}>{title}</Text>
  </TimerEntryWrapper>
);

const TimerWrapper = styled.div<{ $textColor: React.CSSProperties['color'] }>`
  display: flex;
  flex-flow: row nowrap;
  color: ${(props) => props.$textColor};
`;

const TimerEntryWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  & + & {
    margin-left: ${spacing.space32};
  }

  & h3,
  & h6 {
    margin: 0;
  }
`;

const TimerNumber = styled(Text)`
  width: 2.5ch;
`;

export default Timer;
export type { TimerProps };
